<template>
  <div id="invoice-modal">
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <transition name="fade" mode="out-in" appear>
        <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
          <fieldset>
            <div class="column is-12">
              <valid-select
                placeholder="Hoa"
                vid="hoaID"
                required="true"
                rules="required"
                v-model="formData.selectedHoaId"
                :disabled="invoice ? true : false"
                :readonly="invoice ? true : false"
              >
                <option
                  v-for="option in hoasByVendorGlobal"
                  :value="option.value"
                  :key="option.value"
                  >{{ option.label }}</option
                >
              </valid-select>
            </div>
            <div class="column is-12">
              <valid-input
                style="width:50px !important;"
                name="InvoiceNumber"
                label="Invoice Number"
                maxlength="50"
                type="text"
                vid="InvoiceNumber"
                placeholder="Invoice Number"
                spellcheck="true"
                aria-label="Incoice Number"
                rules="max:50|required"
                class="is-small"
                v-model="formData.InvoiceNumber"
                :disabled="(invoice && status === 'Paid') || cancelled === true ? true : false"
                :readonly="(invoice && status === 'Paid') || cancelled === true ? true : false"
              />
            </div>
            <div class="columns pl-3 pt-3">
              <b-field
                class="column is-5"
                label="Billing Period Start Date"
                :message="
                  !formData.StartDate && wasStartDateChanged
                    ? 'Billing Period Start Date is required'
                    : ''
                "
                :type="!formData.StartDate && wasStartDateChanged ? 'is-danger' : 'none'"
              >
                <b-datepicker
                  v-model="formData.StartDate"
                  label="Billing Period Start Date"
                  rules="required"
                  message="Billing Period Start Date is required"
                  aria-required="Billing Period Start Date is required"
                  :show-week-number="showWeekNumber"
                  placeholder="Click to select Billing Period Start Date..."
                  icon="calendar-today"
                  trap-focus
                  :disabled="(invoice && status === 'Paid') || cancelled === true ? true : false"
                  :readonly="(invoice && status === 'Paid') || cancelled === true ? true : false"
                ></b-datepicker>
              </b-field>
              <div class="column is-1" style="padding-top: 40px !important;">TO</div>
              <b-field
                class="column is-5"
                label="Billing Period End Date"
                :message="
                  !formData.EndDate && wasEndDateChanged
                    ? 'Billing Period End Date is required'
                    : ''
                "
                :type="!formData.EndDate && wasEndDateChanged ? 'is-danger' : 'none'"
              >
                <b-datepicker
                  v-model="formData.EndDate"
                  label="Billing Period End Date"
                  rules="required"
                  @change="wasEndDateChanged = true"
                  aria-required="Billing Period End Date is required"
                  :show-week-number="showWeekNumber"
                  placeholder="Click to select Billing Period End Date..."
                  icon="calendar-today"
                  trap-focus
                  :disabled="(invoice && status === 'Paid') || cancelled === true ? true : false"
                  :readonly="(invoice && status === 'Paid') || cancelled === true ? true : false"
                ></b-datepicker>
              </b-field>
            </div>

            <b-field
              v-if="invoice && status !== 'Paid'"
              label="Cancel Reason"
              :type="beingCancelled ? 'is-danger' : ''"
              :message="beingCancelled ? 'Please enter a reason for cancelling.' : ''"
            >
              <b-input
                description-of-request
                type="textarea"
                v-model="formData.cancelReason"
                :disabled="(invoice && status === 'Paid') || cancelled === true ? true : false"
                :readonly="(invoice && status === 'Paid') || cancelled === true ? true : false"
              ></b-input>
            </b-field>
            <div class="box is-8" v-if="!invoice">
              <b-field
                class="column is-5"
                label="Upload Invoice"
                :message="
                  wasUploadedChanged && !wasUploaded
                    ? 'Please upload an invoice. This is required.'
                    : ''
                "
                :type="wasUploadedChanged && !wasUploaded ? 'is-danger' : 'none'"
              >
                <DocModal
                  :wasUploaded.sync="wasUploaded"
                  :apInvoiceID="newApInvoiceId"
                  :closeFromParent="closeFromParent"
                  :hideButtons="true"
                  :submitUpload="submitUpload"
                  :readyForSubmit="readyForSubmit"
                  :autoClose="autoClose"
                  :vendorHoaID="formData.selectedHoaId"
                >
                </DocModal>
              </b-field>
            </div>
            <div v-if="status !== 'Paid' && !invoice" class="pb-5" :style="{ float: 'right' }">
              <button type="submit" class="button is-primary is-rounded">
                Submit Invoice
              </button>
            </div>
            <div
              v-else-if="status !== 'Paid' && invoice && cancelled === false"
              class="pb-5"
              :style="{ float: 'right' }"
            >
              <button
                type="button"
                @click.prevent="onFormCancel"
                class="button is-primary is-rounded"
              >
                Cancel Invoice</button
              ><span class="pl-3"></span>
              <button
                type="button"
                @click.prevent="onFormUpdate"
                class="button is-primary is-rounded"
              >
                Update Invoice
              </button>
            </div>
            <div v-else class="pb-5" :style="{ float: 'right' }">
              <button
                type="button"
                @click.prevent="closeModal()"
                class="button is-primary is-rounded"
              >
                Close
              </button>
            </div>
          </fieldset>
          <fieldset>
            <ValidationProvider vid="providerErrors" v-slot="{ errors }">
              <div class="field">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </fieldset>
        </form>
      </transition>
    </ValidationObserver>
  </div>
</template>

<script>
// Components
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { ValidationError } from '@/services/errors'
import { mapState, mapGetters } from 'vuex' //mapGetters
import DocModal from '@/components/DocumentInvoiceModal'
import $ from 'jquery'
import _get from 'lodash/get'
import { hoaUtility } from '@/services/Hoa/store'
import ValidSelect from '@/components/inputs/ValidSelect'
import { vendorInvoice } from '@/services/Vendors/VendorInvoices/store'

// components
import ValidInput from '@/components/inputs/ValidInput'

import moment from 'moment'

import { notifyMessage, notifyError } from '@/services/notify'

let DEFAULT_FORM_VALUES = {
  selectedHoaId: null,
  payeeInformation: '',
  invoiceNumber: '',
  postingDate: null,
  financialPeriod: null,
  startDate: null,
  endDate: null,
  cancelReason: ''
}

export const defaultDateFormat = 'MM/dd/yyyy'

export default {
  name: 'InvoiceVendorModal',

  props: {
    ownerID: Number,
    invoice: Object
  },

  components: {
    ValidInput,
    ValidationObserver,
    ValidationProvider,
    ValidSelect,
    DocModal
  },

  data: function() {
    return {
      formComplete: false,
      formData: {
        ...DEFAULT_FORM_VALUES
      },
      loading: false,
      formatDateFn: function(value) {
        return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
      },
      isDebug: true,
      selectedRow: null,
      buttonText: '',
      openOnFocus: true,
      isFetching: false,
      data: [],
      showWeekNumber: false,
      newApInvoiceId: 0,
      submitUpload: false,
      enableCreateButton: false,
      hoasByVendorGlobal: [],
      autoClose: true,
      wasUploaded: false,
      cancelled: false,
      beingCancelled: false,
      wasUploadedChanged: false,
      wasEndDateChanged: false,
      wasStartDateChanged: false,
      status: ''
    }
  },

  created() {},

  computed: {
    ...mapState({
      ...mapGetters('user', ['authUser', 'retrieveUser'])
    }),
    ...mapGetters('accounting', ['payeeDropDownList'])
  },

  mounted() {
    this.loading = true

    this.loadHoasByVendorGlobalId()

    if (this.invoice) {
      console.debug('invoice=' + JSON.stringify(this.invoice))

      this.status = _get(this.invoice, 'apInvoiceApproval.apInvoiceStatus.status', '')

      this.formData.selectedHoaId = this.invoice.hoaID
      this.formData.InvoiceNumber = this.invoice.invoiceNumber
      this.formData.StartDate = new Date(this.invoice.startDate)
      this.formData.EndDate = new Date(this.invoice.endDate)
      this.formData.cancelReason = this.invoice.cancelledReason

      if (this.formData.cancelReason.length > 0) {
        this.cancelled = true
      }

      console.debug('formData=' + JSON.stringify(this.formData))
    }

    this.loading = false
  },

  methods: {
    async loadHoasByVendorGlobalId() {
      await hoaUtility
        .dispatch('loadHoasByGlobalVendorId', {
          vendorGlobalID: _get(this, 'authUser.vendorGlobalID', '')
        })
        .then(({ list }) => {
          this.hoasByVendorGlobal = list
          this.loading = false
        })
    },

    getPayload() {
      if (this.isDebug == true) console.debug('this.formData=' + JSON.stringify(this.formData))

      const payload = {
        vendorGlobalID: _get(this, 'authUser.vendorGlobalID', ''),
        hoaID: this.formData.selectedHoaId,
        invoiceNumber:
          this.formData.InvoiceNumber && this.formData.InvoiceNumber != undefined
            ? this.formData.InvoiceNumber
            : null,
        startDate:
          this.formData.StartDate && this.formData.StartDate != undefined
            ? this.formData.StartDate
            : null,
        endDate:
          this.formData.EndDate && this.formData.EndDate != undefined
            ? this.formData.EndDate
            : null,
        apInvoiceID: this.invoice !== undefined && this.invoice ? this.invoice.apInvoiceID : null,
        cancelledReason:
          this.formData.cancelReason !== undefined && this.formData.cancelReason
            ? this.formData.cancelReason
            : null
      }

      if (this.isDebug == true) console.debug('payload=' + JSON.stringify(payload))

      return payload
    },

    closeFromParent() {
      this.closeModal()
    },

    readyForSubmit() {
      this.enableCreateButton = true
    },

    closeModal() {
      this.$emit('update:toggle', (this.toggle = !this.toggle))

      // auto-close modal
      $('#clickclose').click()
    },

    async onFormCancel() {
      if (!this.formData || this.formData == undefined) {
        notifyError('There was a problem cancelling this invoice.')
        return
      }

      this.beingCancelled = false

      if (!this.formData.cancelReason) {
        notifyError('Please enter a reason for the cancellation.')
        this.beingCancelled = true
        return
      }

      const loadingComponent = this.$buefy.loading.open({
        container: null
      })

      try {
        const payload = this.getPayload()
        let validationMessages = ''

        if (payload.startDate == null) {
          validationMessages += '<div>Please select a billing period start date.</div>'
          this.wasStartDateChanged = true
        }

        if (payload.endDate == null) {
          validationMessages += '<div>Please select a billing period end date.</div>'
          this.wasEndDateChanged = true
        }

        if (validationMessages != '') {
          return
        }

        this.loading = true

        await vendorInvoice.dispatch('cancelVendorInvoice', {
          payload: this.getPayload(),
          done: async ({ details }) => {
            if (details.apInvoiceID) {
              notifyMessage(`The invoice was successfully cancelled.`)
              this.closeModal()
            } else {
              notifyError('There was a problem cancelling this vendor invoice.')
            }
          }
        })
      } catch (e) {
        loadingComponent.close()
        // If this is a validation error, get the details for each field
        if (e instanceof ValidationError) {
          this.$refs.form.setErrors(e.fields)
        }

        notifyError('There was a problem cancelling the invoice.' + e)
      }
      loadingComponent.close()
      this.loading = false
    },

    async onFormUpdate() {
      this.beingCancelled = false

      if (!this.formData || this.formData == undefined) {
        notifyError('There was a problem updating this invoice.')
        return
      }

      const loadingComponent = this.$buefy.loading.open({
        container: null
      })

      try {
        const payload = this.getPayload()
        let validationMessages = ''

        if (payload.startDate == null) {
          validationMessages += '<div>Please select a billing period start date.</div>'
          this.wasStartDateChanged = true
        }

        if (payload.endDate == null) {
          validationMessages += '<div>Please select a billing period end date.</div>'
          this.wasEndDateChanged = true
        }

        if (validationMessages != '') {
          return
        }

        this.loading = true

        await vendorInvoice.dispatch('updateVendorInvoice', {
          payload: this.getPayload(),
          done: async ({ details }) => {
            if (details.apInvoiceID) {
              notifyMessage(`The invoice was successfully updated.`)
              this.closeModal()
            } else {
              notifyError('There was a problem updating this vendor invoice.')
            }
          }
        })
      } catch (e) {
        loadingComponent.close()

        // If this is a validation error, get the details for each field
        if (e instanceof ValidationError) {
          this.$refs.form.setErrors(e.fields)
        }

        notifyError('There was a problem updating the invoice.' + e)
      }

      loadingComponent.close()
      this.loading = false
    },

    async onFormSubmit() {
      this.beingCancelled = false

      if (!this.formData || this.formData == undefined) {
        notifyError('There was a problem submitting this invoice.')
        return
      }

      const loadingComponent = this.$buefy.loading.open({
        container: null
      })

      try {
        const payload = this.getPayload()
        let validationMessages = ''

        if (payload.startDate == null) {
          validationMessages += '<div>Please select a billing period start date.</div>'
          this.wasStartDateChanged = true
        }

        if (payload.endDate == null) {
          validationMessages += '<div>Please select a billing period end date.</div>'
          this.wasEndDateChanged = true
        }

        if (this.wasUploaded !== undefined && this.wasUploaded === false) {
          validationMessages += '<div>Please upload an invoice. This is required.</div>'
          this.wasUploadedChanged = true
        }

        if (validationMessages != '') {
          return
        }

        this.loading = true

        await vendorInvoice.dispatch('createVendorInvoice', {
          payload: this.getPayload(),
          done: async ({ details }) => {
            if (details.apInvoiceID) {
              console.debug(JSON.stringify(details))
              this.newApInvoiceId = details.apInvoiceID
              this.submitUpload = true

              notifyMessage(`The invoice was successfully submitted.`)
            } else {
              notifyError('There was a problem creating the vendor invoice.')
            }
          }
        })
      } catch (e) {
        this.submitUpload = false

        loadingComponent.close()

        // If this is a validation error, get the details for each field
        if (e instanceof ValidationError) {
          this.$refs.form.setErrors(e.fields)
        }

        notifyError('There was a problem creating the invoice.')
      }

      loadingComponent.close()
      this.loading = false
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/styles/_variables';

#invoice-modal {
  .step-content {
    @media screen and (max-width: $tablet) {
      padding: 0;
    }
  }

  .step-details {
    background-color: #fff !important;
    padding-left: 0.25rem !important;
    padding-right: 0.5rem !important;
  }

  .b-steps.is-small .steps {
    margin-top: 1rem;
  }

  .b-steps .steps + .step-content {
    padding-top: 0 !important;
  }

  .step-navigation {
    display: flex;
    justify-content: flex-end;
  }

  .my-grid-class {
    height: 400px;
  }

  tbody {
    min-height: 100px !important;
    overflow: auto !important;
  }
}
</style>
