export function mounted() {
  this.current = 1
  if (this.$route.params.filtered) {
    if (this.isDebug == true) {
      console.debug('params=' + JSON.stringify(this.$route.params.filtered))
    }

    this.isForApproval = false

    const filtered = this.$route.params.filtered
    if (filtered == 'all') {
      this.radio = 'all'
      this.reload()
    } else if (filtered == 'cancelled') {
      this.radio = 'cancelled'
    } else if (filtered == 'paid') {
      this.radio = 'paid'
      this.isForApproval = true
    } else if (filtered == 'unpaid') {
      this.radio = 'unpaid'
    }
  } else {
    this.radio = 'all'
    this.reload()
  }
}
