<template>
  <div class="container">
    <div class="box is-12">
      <div class="box is-8">
        <section>
          <div v-if="base64pdf" class="has-text-centered">
            <div>
              <div>
                <div>
                  <pdf
                    style="display: none;"
                    :src="`data:application/pdf;base64,${base64pdf}`"
                    @num-pages="pageCount = new Array($event)"
                  />
                  <!-- Actual document -->
                  <pdf
                    v-for="(page, index) in pageCount"
                    :key="index"
                    :src="`data:application/pdf;base64,${base64pdf}`"
                    :page="index + 1"
                    :scale="scale"
                    :resize="true"
                    :style="{ margin: 'auto', width: `${100 * scale}%` }"
                  />
                </div>
              </div>
              <div class="doc-controls has-text-center">
                <b-field position="is-centered">
                  <div class="control">
                    <b-button @click="zoom(-1)">-</b-button>
                  </div>
                  <div class="control">
                    <b-button @click="zoom(1)">+</b-button>
                  </div>
                </b-field>
              </div>
            </div>
          </div>
          <div>
            <!-- Show the Upload Form -->
            <b-field class="file">
              <b-upload v-model="file" drag-drop accept="application/pdf">
                <section class="section has-width-500">
                  <div class="content has-text-centered">
                    <p>
                      <b-icon icon="file-pdf" size="is-large"> </b-icon>
                    </p>

                    <p v-if="file">
                      {{ file.name }}
                    </p>
                    <p v-else>
                      Drop a PDF file here, or click to select a file from your computer.
                    </p>
                  </div>
                </section>
              </b-upload>
            </b-field>
            <b-button
              v-if="hideButtons === false"
              type="is-primary"
              :disabled="!file"
              @click="submitFile"
              >Upload</b-button
            ><span class="p-2"></span>
            <b-button
              v-if="hideButtons === false"
              type="is-primary"
              :disabled="apInvoice && !this.apInvoice.invoiceDocumentID > 0"
              @click="removeFile"
              >Remove</b-button
            >
            <span class="p-2"></span>
            <b-button
              v-if="autoClose === true && hideButtons === false"
              type="is-primary"
              @click="closeModal"
              >Close</b-button
            >
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import kms from '@/services/kms'
import { mapState, mapGetters } from 'vuex'
import { mapActions } from 'vuex'
import { dragscroll } from 'vue-dragscroll'
import pdf from 'vue-pdf'
import moment from 'moment'
import { notifyError, notifyMessage } from '@/services/notify'

export default {
  name: 'DocumentInvoiceModal',
  components: {
    pdf
  },

  directives: { dragscroll },

  props: {
    apInvoiceID: Number,
    reloadPhotos: Boolean,
    autoClose: {
      default: false,
      type: Boolean
    },
    hideButtons: {
      default: false,
      type: Boolean
    },
    submitUpload: {
      default: false,
      type: Boolean
    },
    vendorHoaID: {
      default: null,
      type: Number
    },
    wasUploaded: {
      default: false,
      type: Boolean
    },
    closeFromParent: Function,
    readyForSubmit: Function
  },

  data: function() {
    return {
      loading: false,
      formatDateFn: function(value) {
        return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
      },
      pageCount: [],
      isDebug: false,
      file: null,
      base64pdf: '',
      scale: 1,
      parsedApInvoiceId: 0
    }
  },

  mounted() {
    this.reload()
  },

  computed: {
    ...mapState('accounting', ['uploadedInvoiceFile']),
    ...mapGetters('accounting', ['apInvoice']),
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    })
  },

  watch: {
    reloadPhotos() {
      this.reload()
    },
    submitUpload() {
      if (this.submitUpload === true) {
        this.submitFile()
      }
    },
    file() {
      console.debug('file=')
      if (this.file !== null) {
        this.ready()
        this.$emit('update:wasUploaded', (this.wasUploaded = true))
      }
    }
  },

  methods: {
    ...mapActions('accounting', ['uploadApInvoiceDocument']),

    reload() {
      try {
        if (this.apInvoiceID && this.apInvoiceID > 0) {
          this.$store
            .dispatch('accounting/getApInvoiceById', {
              apInvoiceID: this.apInvoiceID
            })
            .then(() => {
              if (
                this.apInvoice &&
                this.apInvoice.invoiceDocumentID &&
                this.apInvoice.invoiceDocumentID > 0
              ) {
                if (this.isDebug == true)
                  console.debug('invoiceDocumentId=' + this.apInvoice.invoiceDocumentID)
                this.loadCurrentPdf()
              }
            })
        }
      } catch (e) {
        console.debug('issue reload in doc invoice modal: ' + e)
      }
    },

    closeModal() {
      this.closeFromParent()
    },

    ready() {
      this.readyForSubmit()
    },

    async loadCurrentPdf() {
      try {
        const params = {
          apInvoiceID: this.apInvoiceID,
          asBase64: true
        }

        const path = `/AccountsPayable/APInvoice/GetInvoiceFile`
        const returned = await kms.get(path, {
          params
        })

        if (this.isDebug == true)
          console.debug(
            'path base64 path=' +
              path +
              ' - params' +
              JSON.stringify(params) +
              ' - returned=' +
              JSON.stringify(returned)
          )
        if (returned && returned != undefined) {
          if (this.isDebug == true) console.debug('base64pdf........................' + returned)
          this.base64pdf = returned
        }
      } catch (e) {
        if (this.isDebug == true) console.debug(e)
      }
    },

    async removeFile() {
      if (this.apInvoice && this.apInvoice != undefined) {
        try {
          const payload = {
            apInvoiceID: this.apInvoiceID,
            hoaID: this.hoaId,
            aPPayeeID: this.apInvoice.aPPayeeID,
            invoiceDocumentID: null,
            invoiceNumber: this.apInvoice.invoiceNumber,
            payeeInformation: this.apInvoice.payeeInformation,
            apPayeeID: this.apInvoice.apPayeeID,
            financialPeriod: this.apInvoice.financialPeriod,
            startDate: this.apInvoice.startDate,
            endDate: this.apInvoice.endDate,
            postingDate: this.apInvoice.postingDate,
            codingCompletedDate: this.apInvoice.codingCompletedDate,
            createdDate: this.apInvoice.createdDate,
            modifiedDate: this.apInvoice.modifiedDate,
            deletedDate: this.apInvoice.deletedDate
          }

          const path = '/AccountsPayable/APInvoice'

          const results = await kms.put(path, payload)
          this.base64pdf = ''
          if (results && results != undefined) {
            if (this.isDebug == true)
              console.debug('results.............................=' + JSON.stringify(results))

            notifyMessage(`Successfully removed the document.`)
          } else {
            notifyError('There was a problem removing this document.')
          }
        } catch (e) {
          notifyError(e)
        }
        this.loading = false
      } else {
        notifyError('There was a problem removing this document.')
      }
    },
    async submitFile() {
      if (this.apInvoiceID <= 0) {
        notifyError('There was a problem uploading this document.')
        return
      }

      try {
        const payload = {
          file: this.file,
          apInvoiceID: this.apInvoiceID,
          hoaID:
            this.vendorHoaID !== undefined && this.vendorHoaID && this.vendorHoaID > 0
              ? this.vendorHoaID
              : null
        }

        console.debug('payload for document invoice modal=' + JSON.stringify(payload))

        const results = this.uploadApInvoiceDocument(payload)
        this.base64pdf = ''
        if (results && results != undefined) {
          if (this.isDebug == true)
            console.debug('results.............................=' + JSON.stringify(results))

          setTimeout(() => this.reload(), 2000)

          if (this.submitUpload === false) {
            notifyMessage(`Successfully uploaded the document.`)
          }
        } else {
          notifyError('There was a problem uploading this document.')
        }

        this.loading = false
        if (this.autoClose === true) {
          setTimeout(() => this.closeModal(), 2000)
        }
      } catch (e) {
        notifyError(e)
      }
      this.loading = false
    },

    zoom(direction) {
      const amt = Math.sqrt(2)
      if (direction > 0) {
        this.scale *= amt
      } else {
        this.scale /= amt
      }

      // constrain to min/max
      this.scale = Math.max(0.5, Math.min(4, this.scale))
    }
  }
}
</script>

<style lang="scss">
.modal-h-full {
  left: 50%;
  transform: translate(-50%);
}

#request-image-upload {
  .upload .upload-draggable {
    border: none 0;
    border-radius: 0;
    width: 100%;
    padding: 0;
  }

  .modal-close,
  .delete {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 20;
    margin: 5px 5px 0 0;
  }
}

.doc-viewer {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 130%;
  min-height: 40vh;
  max-height: calc(100vh - 320px);
  border: 1px solid #dbdbdb;

  &.zoom-active {
    cursor: grab;
  }
}

.doc-controls {
  margin-top: -1em;
}
</style>
