import { notifyMessage, notifyProblem, notifyWarning } from '@/services/notify'
import { hoaUtility } from '@/services/Hoa/store'
import kms from '@/services/kms'
import { base64toBlob, showPdf } from '@/services/blobutility'
import _get from 'lodash/get'
import moment from 'moment'
import { mapActions } from 'vuex'
import { notifyError } from '@/services/notify'
import Button from '@/components/buttons/Button'

export const methods = {
  ...mapActions('accounting', ['getAccountsPayableApInvoiceList']),
  ...mapActions('accounting', ['getAccountsReceivableStatistics']),

  createInvoiceModal() {
    console.debug('in createInvoiceModal...')
    this.toggle = true
    this.selectedInvoice = null
  },

  editInvoiceModal(invoice) {
    console.debug('in editInvoiceModal...')

    if (invoice) {
      this.toggle = true
      this.selectedInvoice = invoice
    }
  },

  determineInvoiceRows() {
    console.log('in determineInvoiceRows...' + JSON.stringify(this.accountsPayableApInvoiceList))
    if (this.accountsPayableApInvoiceList && this.accountsPayableApInvoiceList != undefined) {
      var dateFormatFull = 'LL'

      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      })

      this.originalApInvoices = this.accountsPayableApInvoiceList.map(i => {
        var createdDateFormatted = _get(i, 'createdDate', '')

        const status = _get(i, 'apInvoiceApproval.apInvoiceStatus.status', '')

        try {
          createdDateFormatted = moment(createdDateFormatted).format(dateFormatFull)
        } catch (exception) {
          console.error(exception)
        }

        var hoaName = _get(i, 'hoaName', '')

        var apInvoiceID = _get(i, 'apInvoiceID', '')

        var summedInvoiceTotal = 0
        if (i.apInvoiceLineItems) {
          summedInvoiceTotal = i.apInvoiceLineItems.reduce((acc, item) => acc + item.amount, 0)
        }

        return {
          hoaName,
          createdDate: createdDateFormatted,
          invoiceNumber: _get(i, 'invoiceNumber', ''),
          invoiceTotal: formatter.format(summedInvoiceTotal),
          status: status,
          edit: {
            component: Button,
            props: {
              onClick: () => {
                this.editInvoiceModal(i)
              },
              text: 'Edit'
            }
          },
          button: {
            component: Button,
            props: {
              onClick: () => {
                this.downloadPdf(apInvoiceID)
              },
              text: 'View Invoice'
            }
          }
        }
      })
    } else {
      console.log('else....')
    }

    this.rows = this.originalApInvoices
  },

  async downloadPdf(apInvoiceID) {
    if (this.isDebug == true) console.debug('downloadPdf...')

    if (!apInvoiceID || apInvoiceID == undefined) {
      notifyProblem('There was a problem downloading this invoice.')
    }

    try {
      const params = {
        apInvoiceID: apInvoiceID,
        asBase64: true
      }

      const path = `/AccountsPayable/APInvoice/GetInvoiceFile`
      const returned = await kms.get(path, {
        params
      })

      if (this.isDebug == true)
        console.debug(
          'path base64 path=' +
            path +
            ' - params' +
            JSON.stringify(params) +
            ' - returned=' +
            JSON.stringify(returned)
        )
      if (returned && returned != undefined) {
        if (this.isDebug == true) console.debug('base64pdf........................' + returned)
        const contentType = 'application/pdf'
        const blob = base64toBlob(returned, contentType)
        showPdf(blob)
        notifyMessage(`Successfully downloaded the invoice.`)
        return
      }
    } catch (e) {
      if (this.isDebug == true) console.debug(e)
      notifyWarning('There was a problem downloading this invoice.')
    }
  },

  async filterByCreatedDateRange() {
    if (this.isDebug == true) console.debug('...in filterByCreatedDateRange')

    const loadingComponent = this.$buefy.loading.open({
      container: null
    })

    const from = this.$refs.fromCreatedDateComponent.getValue()
    const to = this.$refs.toCreatedDateComponent.getValue()

    if (!from || !from.year) {
      notifyError(`Please select valid created dates.`)
      loadingComponent.close()
      return
    }

    if (this.isDebug == true)
      console.debug('from=' + JSON.stringify(from) + ' - ' + JSON.stringify(to))

    await this.$store
      .dispatch('accounting/getAccountsPayableApInvoiceList', {
        filter: this.radio,
        startCreatedDate: `${from.month}/${from.day}/${from.year}`,
        endCreatedDate: `${to.month}/${to.day}/${to.year}`,
        vendorGlobalID: this.authUser.vendorGlobalID
      })
      .then(() => {
        this.determineInvoiceRows()
        loadingComponent.close()
      })

    loadingComponent.close()
  },

  async filterByHoa() {
    console.debug('...in filterByHoa')

    const loadingComponent = this.$buefy.loading.open({
      container: null
    })

    if (
      this.hoasByVendorGlobal &&
      this.hoasByVendorGlobal.length > 0 &&
      this.selectedHoaId !== undefined &&
      this.selectedHoaId >= 0
    ) {
      if (this.selectedHoaId < 0) {
        notifyError('Please select an Hoa.')
        loadingComponent.close()
        return
      }

      await this.$store
        .dispatch('accounting/getAccountsPayableApInvoiceList', {
          filter: this.radio,
          hoaID: this.selectedHoaId > 0 ? this.selectedHoaId : null,
          vendorGlobalID: this.authUser.vendorGlobalID
        })
        .then(() => {
          this.determineInvoiceRows()
          loadingComponent.close()
        })
    }

    loadingComponent.close()
  },

  toggleFilters: function() {
    this.filters.show = !this.filters.show
  },

  async reloadRequests() {
    console.debug('in reloadRequests...')

    const loadingComponent = this.$buefy.loading.open({
      container: null
    })

    this.loading = true

    await this.$store
      .dispatch('accounting/getAccountsPayableApInvoiceList', {
        filter: this.radio,
        vendorGlobalID: this.authUser.vendorGlobalID
      })
      .then(async () => {
        this.determineInvoiceRows()
        await this.loadHoasByVendorGlobalId()
      })

    loadingComponent.close()

    this.loading = false
  },

  async reload() {
    console.debug('in reload()...')
    this.loading = true

    const loadingComponent = this.$buefy.loading.open({
      container: null
    })

    this.toggle = false

    if (this.isDebug == true) console.debug('in reload...')

    await this.$store
      .dispatch('accounting/getAccountsPayableApInvoiceList', {
        filter: 'all',
        vendorGlobalID: this.authUser.vendorGlobalID
      })
      .then(async () => {
        this.determineInvoiceRows()

        await this.loadHoasByVendorGlobalId()
      })

    loadingComponent.close()

    this.loading = false
  },

  async loadHoasByVendorGlobalId() {
    await hoaUtility
      .dispatch('loadHoasByGlobalVendorId', {
        vendorGlobalID: _get(this, 'authUser.vendorGlobalID', '')
      })
      .then(({ list }) => {
        this.hoasByVendorGlobal = list
        this.loading = false
      })
  },

  redirectToDetails(apInvoiceID) {
    if (this.isDebug == true) console.debug('redirectToDetails=' + apInvoiceID)
    if (apInvoiceID > 0) {
      const detailsPath = '/ap-invoice-detail/' + apInvoiceID + '#tab'
      this.$router.push({
        path: detailsPath,
        params: { id: apInvoiceID }
      })
    }
  }
}
