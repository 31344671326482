export const watch = {
  hoaId() {
    this.reload('')
  },

  radio: 'reloadRequests',

  toggle() {
    if (this.toggle == false) {
      this.reloadRequests()
      this.toggle = false
      if (this.Modal && this.Modal != undefined) {
        this.Modal.$emit('update:toggle', (this.toggle = false))
      }
    }
  }
}
