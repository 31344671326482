import { mapState, mapGetters } from 'vuex'

export const computed = {
  ...mapGetters('accounting', ['apInvoiceStatusList']),
  ...mapGetters('accounting', ['accountsPayableApInvoiceList']),
  ...mapGetters('accounting', ['apInvoiceStatus']),
  ...mapState({
    breakpoint: state => state.win.breakpoint,
    hoaId: state => state.user.selectedHoaId,
    ...mapGetters('user', ['authUser', 'retrieveUser'])
  })
}
