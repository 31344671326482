/*
  import { vendorInvoice } from '@/services/Vendors/VendorInvoices/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'

import { notifyProblem, notifyError } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  /*
    const { list } = await vendorInvoice.dispatch ('getPayeeById', {
      vendorID
    });
  */

  //Payee by Vendor Id
  async getPayeeById({}, { vendorID }) {
    try {
      console.debug('in getPayeeById...' + vendorID)
      const vendor = await kms.get('/AccountsPayable/APPayee', {
        params: {
          apPayeeID: null,
          ownerID: null,
          vendorID: vendorID
        }
      })

      if (isDebug == true) console.debug('getPayeeById=' + JSON.stringify(vendor))

      return {
        result: vendor
      }
    } catch (exception) {
      console.debug(exception)
    }

    return {
      vendor: {}
    }
  },

  async getVendorInvoiceList({}, { payeeID }) {
    console.log('in getVendorInvoiceList...')
    try {
      const result = await kms.get('/AccountsPayable/APInvoice/List', {
        params: {
          payeeID
        }
      })

      if (isDebug == true) console.debug('getVendorInvoiceList log=' + JSON.stringify(result))

      return {
        list: result.results
      }
    } catch (exception) {
      notifyProblem(`The vendor invoice list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  // Add Vendor Invoice
  async createVendorInvoice({ dispatch }, { payload, done }) {
    if (isDebug == true) console.debug('...in createVendorInvoice=' + JSON.stringify(payload))
    try {
      const results = await kms.post(`/AccountsPayable/APInvoice/CreateVendorInvoice`, {
        hoaID: payload.hoaID,
        vendorGlobalID: payload.vendorGlobalID,
        invoiceNumber: payload.invoiceNumber,
        startDate: payload.startDate,
        endDate: payload.endDate
      })

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.apInvoiceID > 0) {
        console.debug(`Successfully created a vendor invoice.`)
        done({ details: results })
      } else {
        notifyError('There was a problem creating this vendor invoice.')
      }
    } catch (exception) {
      notifyError(`There was a problem creating this vendor invoice.`)
      console.error(exception)
    }
  },

  async updateVendorInvoice({ dispatch }, { payload = {}, done }) {
    if (isDebug == true) console.debug('...in updateVendorInvoice')
    try {
      const results = await kms.put(`/AccountsPayable/APInvoice/UpdateVendorInvoice`, payload)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.apInvoiceID > 0) {
        console.debug(`Successfully updated this vendor invoice.`)
        done({ details: results })
      } else {
        console.debug('There was a problem updating this vendor invoice.')
      }
    } catch (exception) {
      notifyError(`There was a problem processing the update for this vendor invoice.`)
      console.error(exception)
    }
  },

  async cancelVendorInvoice({ dispatch }, { payload = {}, done }) {
    if (isDebug == true) console.debug('...in cancelVendorInvoice')
    try {
      const results = await kms.put(`/AccountsPayable/APInvoice/CancelVendorInvoice`, payload)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.apInvoiceID > 0) {
        console.debug(`Successfully cancelled this vendor invoice.`)
        done({ details: results })
      } else {
        console.debug('There was a problem cancelling this vendor invoice.')
      }
    } catch (exception) {
      notifyError(`There was a problem processing the cancel for this vendor invoice.`)
      console.error(exception)
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const vendorInvoice = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
