var field = {
  width: '100px',
  display: 'inline-block',
  paddingRight: '6px',
  textAlign: 'left'
}

export const data = () => ({
  isDebug: true,
  toggle: false,
  radio: 'all',
  loading: false,
  openOnFocus: true,
  isFetching: false,
  apInvoiceID: 0,
  originalApInvoices: [],
  apStatuses: [],
  rows: [],
  hoasByVendorGlobal: [],
  selectedHoaId: null,
  selectedInvoice: null,
  order: 'is-right',
  size: '',
  isSimple: false,
  isRounded: false,
  hasInput: false,
  prevIcon: 'chevron-left',
  nextIcon: 'chevron-right',

  region: {},

  filters: {
    show: true
  },

  pagination: {
    perPage: 10
  },

  columns: [
    {
      field: 'hoaName',
      label: 'HOA Name',
      aria: 'HOA Name',
      width: '50%',
      numeric: true,
      sortable: true,
      searchable: true
    },
    {
      field: 'createdDate',
      label: 'Date Created',
      date: true,
      width: '25%',
      sortable: true,
      searchable: true
    },
    {
      field: 'invoiceNumber',
      label: 'Invoice #',
      aria: 'Invoice #',
      width: '5%',
      numeric: true,
      sortable: true,
      searchable: true
    },
    {
      field: 'invoiceTotal',
      label: 'Amount',
      width: '10%',
      sortable: true,
      searchable: true
    },
    {
      field: 'status',
      label: 'Status',
      aria: 'Status',
      width: '5%',
      sortable: true,
      searchable: true
    }
  ],

  styles: {
    filterButton: {
      float: 'right',
      marginTop: '4px'
    },
    amountField: Object.assign({}, field, {
      width: '125px'
    })
  }
})
