<template>
  <PageContentLayoutOnly>
    <div class="pb-5">
      <b-button @click="createInvoiceModal()" size="is-small" type="is-primary" rounded>
        Submit Invoice
      </b-button>
    </div>
    <div>
      <h3 style="display: inline-block">{{ $t('vendorInvoices.title') }}</h3>
      <div class="pb-5 level">
        <div class="level-right">
          <b-radio v-model="radio" name="gridFilter" native-value="all">
            All
          </b-radio>
          <b-radio v-model="radio" name="gridFilter" native-value="unpaid">
            Unpaid
          </b-radio>
          <b-radio v-model="radio" name="gridFilter" native-value="paid">
            Paid
          </b-radio>
          <b-radio v-model="radio" name="gridFilter" native-value="cancelled">
            Cancelled
          </b-radio>
        </div>
      </div>

      <div
        class="card"
        :style="{
          padding: '20px',
          marginBottom: '20px',
          overflow: 'visible'
        }"
      >
        <div created-dates :style="{ paddingBottom: '12px' }">
          <h6 :style="{ margin: '0 0 6px' }">Created Dates</h6>
          <div
            :style="{
              display: 'flex',
              ...(breakpoint === 'mobile'
                ? {
                    flexDirection: 'column'
                  }
                : {
                    alignItems: 'center'
                  })
            }"
          >
            <DatePicker ref="fromCreatedDateComponent" ariaPrefix="from" />
            <span class="field" :style="{ padding: '0 12px' }">TO</span>
            <DatePicker ref="toCreatedDateComponent" ariaLabel="to date" ariaPrefix="to" />
            <div :style="{ marginBottom: '0.75rem' }">
              <b-button
                class="go-button field is-info is-light is-small is-outline is-rounded"
                :style="{ marginLeft: '12px' }"
                size="is-small"
                type="is-info"
                rounded
                @click="filterByCreatedDateRange"
                >Go</b-button
              >
            </div>
            <div></div>
          </div>
        </div>

        <div created-dates :style="{ paddingBottom: '12px' }">
          <h6 :style="{ margin: '0 0 6px' }">Select HOA</h6>
          <div
            :style="{
              display: 'flex',
              ...(breakpoint === 'mobile'
                ? {
                    flexDirection: 'column'
                  }
                : {
                    alignItems: 'center'
                  })
            }"
          >
            <valid-select
              placeholder="HOA"
              vid="hoaID"
              required="true"
              rules="required"
              v-model="selectedHoaId"
            >
              <option
                v-for="option in hoasByVendorGlobal"
                :value="option.value"
                :key="option.value"
                >{{ option.label }}</option
              >
            </valid-select>
            <span class="pl-3"></span>
            <b-button
              class="go-button is-info is-light is-small is-outline is-rounded"
              size="is-small"
              type="button"
              rounded
              @click="filterByHoa"
              >Go</b-button
            >
          </div>
        </div>
      </div>
    </div>

    <div v-if="rows && rows != undefined">
      <div style="padding-bottom:30px;">
        <ModernTable
          :region="region"
          :filters="filters"
          :rows="rows"
          :columns="columns"
          :pagination="{
            perPage: 12
          }"
          :loading="loading"
        >
        </ModernTable>
      </div>
    </div>

    <Modal v-if="toggle && toggle === true" :toggle.sync="toggle">
      <template v-slot:header>{{ selectedInvoice ? 'View Invoice' : 'Create Invoice' }}</template>
      <InvoiceCreator :invoice="selectedInvoice" :toggle="toggle" />
    </Modal>
  </PageContentLayoutOnly>
</template>

<script>
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import DatePicker from '@/components/inputs/DatePicker'
import ValidSelect from '@/components/inputs/ValidSelect'
import ModernTable from '@/components/tables/Modern/Table'
import InvoiceCreator from '@/components/InvoiceVendorModal'
import Modal from '@/components/Modal'
import { watch } from './keys/watch'
import { mounted } from './keys/mounted'
import { methods } from './keys/methods'
import { data } from './keys/data'
import { computed } from './keys/computed'

export default {
  name: 'VendorInvoices',
  components: {
    PageContentLayoutOnly,
    DatePicker,
    ValidSelect,
    ModernTable,
    InvoiceCreator,
    Modal
  },

  computed,
  data,
  methods,
  mounted,
  watch,

  i18n: {
    messages: {
      en: { vendorInvoices: { title: 'Vendor Invoices' } }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/_variables';

@media screen and (max-width: $tablet) {
  .filter-button {
    display: none;
  }
}

div.amountFrom {
  width: 60px !important;
}

.filter-button {
  color: #838383 !important;

  &.active {
    color: $link !important;
  }
}

.button.is-ghost {
  background: none;
  border-color: transparent;
  color: #7957d5;
  text-decoration: none;
}

.table-a-pagination-container {
  .pagination {
    .pagination-list {
      order: 1 !important;
    }
    .pagination-previous {
      order: 2 !important;
    }
    .pagination-next {
      order: 3 !important;
    }
  }
}
</style>
